.productTop {
  display: flex;
}

.productTopLeft,
.productTopRight {
  flex: 1;
}
.productTopRight {
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  background-color: white;
  border-radius: 10px;
}

.productInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.productInfoTop {
  display: flex;
  align-items: center;
}
.productInfoBottom {
  margin-top: 10px;
}

.productInfoItem {
  width: 150px;
  display: flex;
  justify-content: space-between;
}
.productInfoValue {
  font-weight: 600;
}

.productBottom {
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  background-color: white;
  border-radius: 10px;
}
.productForm {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.productUpload > img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-right: 10px;
}
.productUpload {
  display: flex;
  align-items: center;
}
.productFormLeft {
  display: flex;
  flex-direction: column;
}
.productFormLeft,
.productFormRight {
  flex: 1;
}
.productFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}
.productFormLeft > input,
.productFormLeft > select {
  margin-bottom: 10px;
  width: 100%;
  border: none;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.productFormRight {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}
