.App {
}
a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.container {
  display: flex;
}

.content {
  flex: 6 1;
  background-color: rgb(241, 241, 241);
  overflow-x: hidden;
  padding: 20px;
}

* {
  box-sizing: border-box;
}

.ListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: green;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}
.ListRemove {
  color: red;
  cursor: pointer;
}
.Title {
  font-weight: 600;
  font-size: 24px;
}

.TitleContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.addBtn {
  color: white;
  background-color: green;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
}
.updateBtn,
.saveBtn {
  color: white;
  background-color: blue;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
}
label {
  margin-bottom: 10px;
  color: gray;
}
input,
select {
  margin-bottom: 10px;
  width: 100%;
  border: none;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}
