.productList {
  background-color: white;
  border-radius: 10px;
  width: 100%;
}
.productItem {
  display: flex;
  align-items: center;
  gap: 10px;
}
.productListImg {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
