.topbar {
  width: 100%;
  height: 70px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-size: 30px;
  color: darkblue;
  font-weight: bold;
  padding: 20px;
  text-transform: uppercase;
}
.topRight {
  display: flex;
  gap: 20px;
  align-items: center;
}
.topbarIconContainer {
  position: relative;
  cursor: pointer;
}

.topIconBadge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
