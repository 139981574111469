.widgetSm {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  -moz-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  background-color: white;
  border-radius: 10px;
}
.widgetSmTitle {
  font-size: 22px;
  font-weight: 600;
}
.widgetSmListItem {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.widgetSmList {
  padding: 0;
  margin: 0;
  list-style: none;
}
.widgetSmListItem img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.widgetSmUser {
  display: flex;
  flex-direction: column;
}
.widgetSmUsername {
  font-weight: 600;
}
.widgetSmUserTitle {
  font-weight: 300;
  font-size: 12px;
}
.widgetSmButton {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  padding: 7px 10px;
  background-color: #eeeef7;
  color: #555;
  cursor: pointer;
}
.widgetSmIcon {
  font-size: 16px !important;
  margin-right: 5px;
}
