.newUser {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.newUserForm {
  display: flex;
  flex-wrap: wrap;
}
.newUserItem {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.newUserItem > label {
  margin-bottom: 5px;
  font-size: 14px;
  color: gray;
}

.newUserItem > input {
  border: none;
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px 10px;
}
.newUserGender > input {
}
.newUserGender > label {
  margin: 10px;
  font-size: 18px;
}
.newUserSelect {
  padding: 10px 10px;
  border-radius: 5px;
}

.newUserBtn {
  width: 200px;
  border: none;
  background-color: darkblue;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
