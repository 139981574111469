.userPage {
  padding: 20px;
  border-radius: 10px;
}
.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userAddBtn {
  padding: 8px 16px;
  border: none;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}
.userContainer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.userShow {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  -moz-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  background-color: white;
  border-radius: 10px;
}
.userUpdate {
  border-radius: 10px;
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  -moz-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  background-color: white;
}
.userShowTop {
  display: flex;
  align-items: center;
}
.userShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.userShowTopTitle {
  display: flex;
  flex-direction: column;
}
.userShowName {
  font-weight: 600;
}
.userShowEmail {
  font-weight: 300;
  color: grey;
}
.userShowBottom {
  display: flex;
  flex-direction: column;
}
.userShowInfo {
  display: flex;
  align-items: center;
}

.userUpdateImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.userUpdateForm {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.userUpdateTitle {
  font-size: 22px;
  font-weight: 600;
}
.userUpdateItem {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.userUpdateItem > label {
  margin-bottom: 5px;
  font-size: 14px;
}

.userUpdateItem > input {
  border: none;
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 10px 0;
}

.userUpdateRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.userUpdateBtn {
  padding: 8px 10px;
}
.userUpdateUpload {
  display: flex;
  align-items: center;
}
.userUpdateIcon {
  cursor: pointer;
}
.userUpdateBtn {
  border: none;
  padding: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  border-radius: 5px;
}
.userUpdateLeft,
.userUpdateRight {
  flex: 1;
}
