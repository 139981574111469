.home {
  background-color: rgb(241, 241, 241);
  overflow-x: hidden;
}

.homeWidget {
  display: flex;
  padding: 20px;
  gap: 20px;
}
