.sidebar {
  flex: 1;

  height: calc(100vh - 50px);
  position: sticky;
  top: 50px;
}
.sidebarWrapper {
  padding: 40px;
}
.sidebarMenu {
  text-align: left;
  margin-bottom: 10px;
}
.sidebarTitle {
  font-size: 16px;
  color: #1074cd;
  margin-bottom: 10px;
  font-weight: 500;
}
.sidebarList {
  list-style: none;
  padding: 0px;
}
.sidebarListItem {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 13px;
  width: -webkit-fill-available;
}
.sidebarIcon {
  margin-right: 10px;
  font-size: 20px !important;
}
.sidebarListItem:hover,
.sidebarListItem.active {
  background-color: #daecf9;
}
