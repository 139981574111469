.chart {
  padding: 20px;
  -webkit-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  -moz-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  margin: 20px;
  border-radius: 10px;
  background-color: white;
}

.chartTitle {
  margin-bottom: 20px;
  font-size: 18px;
}
