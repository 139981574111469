.widgetLg {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  -moz-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  background-color: white;
  border-radius: 10px;
}

.widgetLgTitle {
  font-size: 22px;
  font-weight: 600;
}

.widgetLgImg {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.widgetLgButton {
  padding: 5px 7px;
  border: none;
  border-radius: 10px;
}
.widgetLgButton.Approved {
  background-color: lightgreen;
}
.widgetLgButton.Declined {
  background-color: rgb(255, 122, 122);
}
.widgetLgButton.Pending {
  background-color: rgb(122, 195, 255);
}
.widgetTable {
  width: 100%;
}
.widgetLgTr {
  text-align: left;
}

.widgetLgUser {
  display: flex;
  align-items: center;
}
