.featured {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.featuredItem {
  background-color: white;
  flex: 1;
  margin: 0 20px;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  -moz-box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
  box-shadow: 0px 3px 34px 0px rgba(34, 60, 80, 0.05);
}

.featuredTitle {
  font-size: 20px;
}

.featuredMoneyContainer {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.featuredMoney {
  font-size: 30px;
  font-weight: 600;
}
.featuredMoneyRate {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.featuredIcon {
  font-size: 14px;
  margin-left: 5px;
  color: green;
}

.featuredIcon.negative {
  color: red;
}
.featuredSub {
  font-size: 15px;
  color: gray;
}
